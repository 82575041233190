import { createGlobalStyle } from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Homemade+Apple&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
  * {
    box-sizing: border-box;
  }
  html,
  body {
    font-family: 'Assistant', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    font-stretch: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
  }



::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: .4rem;
  background: ${props => props.theme.grey01ST};
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: ${props => props.theme.orangeST};
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}
.paypal-button-container {
  max-width: 100% !important;
}

  h1,
  h2,
  h3,
  h4,
  p, ul, li {
    font-family: "Assistant", sans-serif;
    color: ${props => props.theme.grey06ST}; 
    font-size: 16px;
    letter-spacing: 0.06rem;
    /* line-height: 1.7; */
    @media only screen and (max-width: 768px) {
      /* font-size: inherit; */
    }
  }

  ul.none {
  list-style-type: none;
  }

 .grey {
  color: ${props => props.theme.grey06ST};
 }

 .white {
  color: #fff;
 }

 .uppercase {
    text-transform: uppercase;
  }
  .lowercase {
    text-transform: lowercase;
  }
  .initial {
    text-transform: initial;
  }
  .capitalize {
    text-transform: capitalize;
 }
  .center {
    text-align: center !important;
  } 
  .right {
    text-align: right;
  }
  .left {
    text-align: left !important;
  }
  .italic {
    font-style: italic;
  }

  .gras,.bold {
    font-weight: 700;
  }
  .red {
    color: ${props => props.theme.redST};
  }
  .black {
    color: ${props => props.theme.blackST};
  }
  .green {
    color: ${props => props.theme.greenST};
  }
  .orange {
    color: ${props => props.theme.orangeST} ;
  }
  .majuscules {
    text-transform: uppercase;
  }
  .sansRetour {
    white-space: nowrap;
  }

  .fs14 {
    font-size: 14px;
  }
  .fs16 {
    font-size: 16px;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs18 {
    font-size: 18px;
  }

  .fs19 {
    font-size: 19px;
  }
  

  .fs20 {
    font-size: 20px;
  }

  .fs21 {
    font-size: 21px;
  }

  .fs23 {
    font-size: 23px;
  }

  .fs30 {
    font-size: 30px !important;
  }

  .fs35 {
    font-size: 35px !important;
  }

  .fs40 {
    font-size: 40px !important;
  }

  .fw600 {
    font-weight: 600;
  }

  .underline {
    text-decoration: underline;
  }
  .m0 {
    margin: 0 !important;
  }
  .mb0 {
    margin-bottom: 0 !important;
  }
  
  .mb30 {
    margin-bottom: 30px;
  }

  .mt50 {
    margin-top: 50px;
  }
  .mt20 {
    margin-top: 20px;
  }

.homemadeTitle {
  font-family: "Homemade Apple", sans-serif;
  /* font-size: 23px; */
  }

.ignoreParent {
  width: calc(100% + 200px) !important; 
  margin-left: -100px; 
  margin-right: -100px;
  @media screen and (max-width: 768px) {
    width: calc(100% + 40px) !important;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.linesGreen {
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
gap: 20px;
  ::before {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: linear-gradient(to left, ${props => props.theme.redST}, ${props => props.theme.greenST} 100%);
    margin: 20px 0;
  }

  ::after {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: linear-gradient(to left, ${props => props.theme.greenST}, ${props => props.theme.redST} 100%);
    margin: 20px 0;
  }
  
}
.linesRed {
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
gap: 20px;
  ::before {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: linear-gradient(to left, ${props => props.theme.greenST}, ${props => props.theme.redST} 100%);
    margin: 20px 0;
  }

  ::after {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: linear-gradient(to left, ${props => props.theme.redST}, ${props => props.theme.greenST} 100%);
    margin: 20px 0;
  }
  
}

  h1 {
  margin: 20px auto;
  text-align: center;
  font-weight: 600 ;
  font-size: 50px;
  letter-spacing: 0.2rem;
  position: relative;
  margin-bottom: 1.5rem;
    @media only screen and (max-width: 992px) {
      font-size: 42px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 32px;
    margin: 2rem 0;
  } 
  h2.small {
    color: ${props => props.theme.blackST};
    /* text-align: center; */
    font-size: 20px;
  }

  h2.blog {
    text-align: center;
    text-transform: initial;
  }


  h3 {
    font-size: 24px;
    position: relative;
    margin: 2rem auto;
    text-align: center;
  }
 

  h4 {
    color: ${props => props.theme.blackST};
    margin: 2rem auto;
    font-size: 21px;
    @media only screen and (max-width: 768px) {
      font-size: 20px; 
    }
  }  
  
  h5 {
    margin: 25px 0px;
    color: ${props => props.theme.beigeFonce};
    font-size: 23px;
    font-weight: 700;
    @media only screen and (max-width: 768px) {
      font-size: 18px; 
    }
  }

  *:focus,
  *:active {
    outline: 0 !important;
  }

a,
button {
  transition: opacity .3s;
}
a {
font-family: inherit;
/* text-align: left; */
color: inherit;
cursor: pointer;
color: ${props => props.theme.grey02ST};
transition: color .3s;
}
a:hover {
  /* opacity: .9; */
  color: ${props => props.theme.redST};
}
 
a:active,
button:hover,
button:active {
}

table {
    font-family: "Assistant", sans-serif;
    width: 100%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;	
    font-size: 15px;
}
	table tbody {
		overflow: auto;
		/* max-width: 85vw; */
		margin: 0 auto;
	}
   table td {
    text-align: center;
    border: 1px solid ${props => props.theme.grey02ST};
    padding: 10px;
}
table th{
	text-align:center;
	background: ${props => props.theme.orangeST};
  border: 1px solid ${props => props.theme.grey02ST};
	color:#FFF;
	padding:2pt;
}

table.greenTable th{
	text-align:center;
	background: ${props => props.theme.greenST};
  border: 1px solid ${props => props.theme.grey02ST};
	color:#FFF;
	padding:2pt;
}
 
input {
  font-family: "Assistant", sans-serif ;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 15px;
  padding: 8px;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
 opacity: 1 !important;
 margin: 0;
 width: 15px;
 height: 25px;
}
  
textarea {
  height: 140px !important;
}
  
input[type="textarea"] {
width: 1.1em;
height: 1.1em;
margin-top: 0;
}
input[type="checkbox"]:checked {
border-color: ${props => props.theme.redST};
background-color: ${props => props.theme.redST};
}
input[type="checkbox"]:focus {
box-shadow: 0 0 0 0.25rem #df5a4e2e;
border-color: ${props => props.theme.redST};
}

input[type="radio"]:checked {
border-color: ${props => props.theme.redST};
background-color: ${props => props.theme.redST};
}
input[type="radio"]:focus {
box-shadow: 0 0 0 0.25rem #df5a4e2e;
border-color: ${props => props.theme.redST};
}

//___________Blogs ST
.cardBlog {
  width: 360px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

//-----------------------------------BandeauSlider  :

.bandeauSlider> .arrow{
fill: #fff;
width: 25px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 115px) !important;
}

//-----------------------------Pagination ANTD


.anticon > svg {
    fill: ${props => props.theme.redST};
}

.ant-pagination .ant-pagination-item {
    font-family: 'Assistant', sans-serif;
}
.ant-pagination .ant-pagination-item-active {
    border: 2px solid ${props => props.theme.redST};
    background: transparent;
    font-family: 'Assistant', sans-serif;
    
}
.ant-pagination .ant-pagination-item-active:hover {
    border-color: ${props => props.theme.greenST}; 
}

.ant-pagination-total-text {
  @media only screen and (max-width: 768px) {
    display: block !important;
  }
}


// ------------------------------------------------------ Keen slider 

.navigation-wrapper {
  position: relative;
}

.dots {
  position: relative;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
}


.dot {
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.dot:hover {
  background: ${props => props.theme.greenST};
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: ${props => props.theme.greenST};
  /* width: 25px;
  height: 25px; */
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%; 
 transform: translateY(-50%);
  fill: ${props => props.theme.greenST};
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: ${props => props.theme.greenST};
}

.ant-card .ant-card-actions>li >span:hover {
  color: ${props => props.theme.redST};
}

.ant-menu-submenu-popup.ant-menu-submenu {
  width: 100%;
  left: 0 !important;
}
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  width: 100%;
  display: flex;
}

.swiper-button-next::after,.swiper-button-prev::after{
    font-size: 35px !important;
    font-weight: 900;
    color:  #fff !important;
}
.swiper-button-next{
    right: 10px !important;
}
.swiper-pagination .swiper-pagination-bullet {
  background: ${props => props.theme.greenST};
  width: 15px;
  height: 15px;
}
.swiper-pagination .swiper-pagination-bullet:hover {
  background-color: ${props => props.theme.greenST};
  opacity: 1 !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover {
  border: 4px solid ${props => props.theme.orangeST};
}

.image-gallery-icon:hover{
  color: ${props => props.theme.orangeST};
}

.image-gallery.mag {
  width: 100%;
}

`

export default GlobalStyles
