import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import EspaceST from "../DesignSystem/Espace";
import FormSearchST from "../FormsST/FormSearchSelf";
import ReactHtmlParser from 'react-html-parser';
import LinkNoirST from "../DesignSystem/SelfTissus/LinkNoir";
import LinkST from "../DesignSystem/SelfTissus/Link";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import picDefault from "../../assets/images/photo.jpg";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";
import { InputStyled, FormStyled, LabelStyled } from "../DesignSystem/SelfTissus/Forms";
import { navigate } from "@reach/router";

const Wrap = styled.div`
    padding: 0;
    @media (max-width: 768px) {
        padding: 0;
    }
`
const WrapInside = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding: 20px 0;
    @media (max-width: 768px) {
    }
`
const Flex = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;
justify-content: center;
gap: 30px;
padding: 20px 0;
`

const Result = styled.a`
display: flex;
width: 322px;
position: relative;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
cursor: pointer;
text-decoration: none;
&> img {
    min-height: 322px;
    min-width: 322px;
    width: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 0px !important;
}
&:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

@media screen and (max-width: 768px) {
width: 100%;
}
`
const Txt = styled.div`
margin: 30px 0 0 0;
font-size: 17px;
`

const Titre = styled.div`
width: 100%;
padding: 0;
&>h2 {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }
}

`;

const FilterProds = styled.div`
width: 15%;
@media screen and (max-width: 992px) {
  width: 25%;
}
@media screen and (max-width: 768px) {
  display: none;
}
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&.selected {
  color: ${props => props.theme.redST};
  font-weight: 600;
}
&.sub {
  font-size: 14px;
  color: ${props => props.theme.grey02ST};
}
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Prods = styled.div`
width: 80%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;
justify-content: flex-start;
gap: 30px;
@media screen and (max-width: 768px) {
    width: 100%;
}
`;

const Infos = styled.div`
width: 100%;
padding: 0 10px;
`;

export default function RechercheResultats() {
    const location = useLocation();
    const data = location?.state?.data?.Recherches || []; 
    const inputValue = location?.state?.inputValue || '';
    const queryParams = new URLSearchParams(location.search);
    const initialInputValue = queryParams.get('recherche') || '';
    const [sortedItems, setSortedItems] = useState([]);
    const [num, setNum] = useState(0);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
    const [loadingStates, setLoadingStates] = useState([]);
    const [selectedTri, setSelectedTri] = useState('');

    useEffect(() => {
    // Initialize sortedItems when data is available
    setSortedItems(data);
    }, [data]);
    useEffect(() => {
      // Initialize loading states to true for each item
      setLoadingStates(sortedItems?.map(() => true));
    }, [sortedItems]);
    const handleImageLoad = (index) => {
      setLoadingStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = false;
        return newStates;
      });
    };
    
    useEffect(() => {
        let count;
        count = data?.length;
        setNum(count);
      }, [data]);

      useEffect(() => {
        // Update the search parameter in the URL when inputValue changes
        const params = new URLSearchParams(location.search);
        if (inputValue) {
          params.set('recherche', inputValue);
        } else {
          params.delete('recherche');
        }
       
      }, [inputValue, location.search]);

      const handleFilterChange = (value) => {
        setSelectedTri(value);
        let sortedArray = [...data];
        if (value === 'A') {
            sortedArray?.sort((a, b) => (a.Lib > b.Lib ? 1 : -1));
        } else if (value === 'Z') {
            sortedArray?.sort((a, b) => (a.Lib < b.Lib ? 1 : -1));
        } else if (value === 'PrixC') {
          sortedArray?.sort((a, b) => a?.Meta?.Meta?.Tarif - b?.Meta?.Meta?.Tarif);
        } else if (value === 'PrixD') {
          sortedArray?.sort((a, b) => b?.Meta?.Meta?.Tarif - a?.Meta?.Meta?.Tarif);
        }
    
        setSortedItems(sortedArray);
      };

        return(
        <>
        <Wrap>
            <WrapInside>
                <FormSearchST placeholder="Recherche" recherche={inputValue} rechercheParams={initialInputValue}/>
                {inputValue && (
                    <h3>
                    {num === 0 || num === undefined
                      ? `Désolé, aucune correspondance n'a été trouvée pour votre recherche '${inputValue}' dans nos contenus.`
                      : num === 1
                      ? `1 résultat trouvé sur ce site pour votre recherche '${inputValue}'.`
                      : `${num} résultats trouvés sur ce site pour votre recherche '${inputValue}'.`}
                    </h3>
                    )}
            </WrapInside>
            <FormStyled>
              <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={(event) => handleFilterChange(event.target.value)}
                  value={selectedTri}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC" selected={selectedTri === "PrixC"}>Prix croissant</option>
                  <option value="PrixD" selected={selectedTri === "PrixD"}>Prix décroissant</option>
                  <option value="A" selected={selectedTri === "A"}>A à Z</option>
                  <option value="Z" selected={selectedTri === "Z"}>Z à A</option>
                </InputStyled>
              </FormStyled>
        <Flex>
        <FilterProds>
          <ul className="none">
              <Tri>Trier par</Tri>
              <li>
                <Link onClick={() => { handleFilterChange('PrixC'); setSelectedTri('PrixC'); }}
                style={{ color: selectedTri === 'PrixC' ? '#DF5A4E' : '#121212bf' }}>
                  Prix croissant
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange('PrixD'); setSelectedTri('PrixD'); }}
                style={{ color: selectedTri === 'PrixD' ? '#DF5A4E' : '#121212bf' }}>
                  Prix décroissant
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange('A'); setSelectedTri('A'); }}
                style={{ color: selectedTri === 'A' ? '#DF5A4E' : '#121212bf' }}>
                  De A à Z
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange('Z'); setSelectedTri('Z'); }}
                style={{ color: selectedTri === 'Z' ? '#DF5A4E' : '#121212bf' }}>
                  Z à A
                </Link>
              </li>
            </ul>
        </FilterProds>
        <Prods>
        {
          sortedItems && sortedItems?.map((item, index) => (
               
                    <Result key={index} href={item?.slug}>
                        {/* <img src={item?.Meta?.Meta?.URLImage || picDefault} */}
                        {loadingStates[index] && <SpinnerInlineMiniST/>}

                        <img src={item?.Meta?.Meta?.URLImage}
                           onError={(e) => {
                           e.target.onerror = null;
                           e.target.src = picDefault;
                          }} 
                          onLoad={() => handleImageLoad(index)}
                          loading="lazy"
                        alt={item?.Meta?.Meta?.Titre}/>
                        {/* <LienNoirST href={item?.slug} lib={ReactHtmlParser(item?.Lib)}/> */}
                        {/* {item?.Description && <Txt>{item?.Description}</Txt>} */}
                        <Infos>
                        <Titre><h2 className="initial m0 grey fs17 m0">{ReactHtmlParser(item?.Lib)}</h2></Titre>
                       {item?.Meta?.Meta?.Tarif && <p className="uppercase orange fw600 m0">{item?.Meta?.Meta?.Tarif?.toFixed(2)} €</p>}
                       </Infos>
                    </Result>
                ))
            }
            </Prods>
            </Flex>
        </Wrap>
        </>
    )
}

