import React from "react";
import { navigate } from '@reach/router';
import { useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { useSelector,useDispatch } from 'react-redux';
import {PopUp,PopUpTitle, Wrap, ButFermer} from "../DesignSystem/PopUpST";
import {IoCloseCircleOutline} from "react-icons/io5";
import styled from "styled-components";
import LinkST from "../DesignSystem/SelfTissus/Link";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import picDefault from "../../assets/images/photo.jpg";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { FormGroup, Label } from "reactstrap";

const Prod = styled.div`
&>img {
  width: 100px;
  height: auto;
}
`
const Prix = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
font-size: 20px;
font-weight: 700;
span {
  color: ${(props) => props.theme.redST};
  font-size: 24px;
}
`;

export default function PopPanierST({
  onClick, 
  className, 
  data,
  quantity,
  totalPrice,
  mode
}) {
console.log("data",data);
// const basket = useSelector(state => state.basket);
// const [price, setPrice] = useState(totalPrice);
// const dispatch = useDispatch();
// const [quantity2, setQuantity2] = useState(quantity);
// const currentQuantity = basket.items.find(item => item.ID === data.ID)?.quantity || 0;
// console.log("currentQuantity",currentQuantity);
// const handleQuantityChange = (e) => {
//   const newQuantity = parseInt(e.target.value, 10);
//   setQuantity2(newQuantity);
//   setPrice((newQuantity * data?.Prix).toFixed(2));


//   dispatch({
//     type: "UPDATE_BASKET",
//     payload: {
//         id: data.ID, 
//         quantity: currentQuantity + newQuantity
//     }
// });
// };
// console.log("basket",basket);

  return (
    <>
                  <PopUp className={className}>
                    <ButFermer onClick={onClick} className="white"><IoCloseCircleOutline/></ButFermer>
                      <PopUpTitle>Produit ajouté au panier avec succès</PopUpTitle>
                      <Wrap>
                        <Prod>
                          <h3>{ReactHtmlParser(data?.libArticle)}</h3>
                          {mode ==="atelier" ?
                          <img src={data?.img?.url ? data?.img?.url : picDefault} alt={data?.lib} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = picDefault;
                          }}
                          loading="lazy"/>
                          :
                          <img src={data?.Photos[0].image?.url} alt={data?.lib} onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = picDefault;
                            }}
                            loading="lazy"/>
                          }
                          {/* <FormGroup floating> */}
                            {/* <InputStyled
                              id="prix"
                              name="number"
                              placeholder="Quantité"
                              type="number"
                              value={quantity2}
                              onChange={handleQuantityChange}
                              min="1"
                            />
                              <Label for="prix">
                              Quantité
                              </Label>
                            </FormGroup>
                            <Prix><span>{price} €</span></Prix> */}
                        </Prod>
                         <LinkST lib={"Voir mon panier"} 
                         href="/panier"
                            background={props => props.theme.greenST}
                            width={"100%"}
                            /> <BoutonST lib={"Continuer mes achats"} 
                            background={props => props.theme.orangeST}
                            width={"100%"} onClick={onClick}/>
                      </Wrap>
                    </PopUp>
    </>
  );
}
