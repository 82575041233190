import React, {useState,useEffect} from "react";
import axios from "axios";
import styled from 'styled-components';
import { Section, WrapperProds } from "../DesignSystem/SelfTissus/ContainersST";
import { InputStyled,LabelStyled, FormStyled } from "../DesignSystem/SelfTissus/Forms";
import { Card,Badge,Space } from 'antd';
import { FaRegHandPointRight } from "react-icons/fa6";
import imgDefault from "../../assets/images/photo.jpg";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import BoutonTransparentBtnST from "../DesignSystem/SelfTissus/BoutonTransparentBtn";
import { Helmet } from "react-helmet";

const CardStyled = styled(Card)`
width: 320px;
background-color: transparent;
border: none;
border-radius: 0 !important;
&>.ant-card-body {
  padding: 10px;
  border-radius: 0 !important;
}
@media screen and (max-width: 1100px) {
  width: 290px;
}
@media screen and (max-width: 992px) {
  width: 255px;
}
@media screen and (max-width: 768px) {
  width: 100%;
  min-width: 300px;
  margin: 0 auto;
  max-height: unset;
  min-height: unset;
}
&>.ant-card-body > h2 {
}
/* &>.ant-card-cover {
  height: 360px;
} */
`;

const Img = styled.img`
min-height: 322px;
min-width: 322px;
width: 100%;
object-fit: cover;
border-radius: 0 !important;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
`;

const SpaceStyled = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
justify-content: center;
align-items: flex-start;
flex-wrap: wrap;

`

const Prod = styled.a`
display: flex !important;
color: ${props => props.theme.greenST} !important;
align-items: center;
justify-content: flex-start;
gap: 10px;
font-size: 14px;
&:hover {
  color: ${props => props.theme.orangeST} !important;
}
`;

const Prods = styled.div`
position: relative;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 30px;
width: 80%;
@media screen and (max-width: 992px) {
  width: 70%;
}
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const ProdsSmall = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
gap: 10px;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const FilterProds = styled.div`
width: 15%;
@media screen and (max-width: 992px) {
  width: 25%;
}
@media screen and (max-width: 768px) {
  display: none;
}
`;
const FilterProds2 = styled.div`
display: flex;
align-items: center;
width: 100%;
gap: 50px;
justify-content: flex-end;
@media screen and (max-width: 768px) {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Image = styled.div`
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`;

const Result = styled.p`
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
`

export default function AteliersListeST({idCategorie,onNbrAteliersChange}) {
const [isLoading, setIsLoading] = useState(true); 
const [isLoadingMore, setIsLoadingMore] = useState(false);
const [dataAteliers, setDataAteliers] = useState([]);
const [dataAteliersNbr, setDataAteliersNbr] = useState(0);
const [filterOption, setFilterOption] = useState('');

const handleFilterChange = (option) => {
  if (typeof option === 'string') {
    setFilterOption(option);
  } else {
    setFilterOption(option.target.value);
  }
};
const getFilteredData = () => {
  let filteredData = [...dataAteliers];
  switch (filterOption) {
    case 'PrixC':
      filteredData.sort((a, b) => a.PUTTC - b.PUTTC);
      break;
    case 'PrixD':
      filteredData.sort((a, b) => b.PUTTC - a.PUTTC);
      break;
      case 'a':
        filteredData?.sort((a, b) => {
          if (!a?.lib) return 1;
          if (!b?.lib) return -1;
          return a.lib.localeCompare(b.lib);
        });
        break;
      case 'z':
        filteredData?.sort((a, b) => {
          if (!a?.lib) return 1;
          if (!b?.lib) return -1;
          return b.lib.localeCompare(a.lib);
        });
      break;
    default:
      break;
  }
  return filteredData;
};

function formatDuration(duration) {
  const [hours, minutes] = duration?.split(':');
  const formattedHours = `${parseInt(hours)}h`;
  const formattedMinutes = minutes !== '00' ? `${parseInt(minutes)}` : '';
  return `${formattedHours}${formattedMinutes}`;
}

useEffect(() => {
  setIsLoading(true);
  axios.post(`/users/atelierliste`,{Limite : 20, OFFSET : 0, UidCategorie : idCategorie})
  .then((response) => {
    setDataAteliers(response?.data?.Ateliers);
    setDataAteliersNbr(response?.data?.AteliersNbr);
    setIsLoading(false);
    if (onNbrAteliersChange) {
      onNbrAteliersChange(response?.data?.Ateliers?.length);
    }
  })
  .catch((err) => {
    console.log("err", err);
  });
}, [idCategorie]);

const handleMoreAteliers = (event, offset, limit) => {
  event.preventDefault();
  setIsLoadingMore(true);
  axios.post(`/users/atelierliste`,{Limite : limit, OFFSET : offset })
  .then((response) => {
    setDataAteliers([...dataAteliers, ...response?.data?.Ateliers]);
    setIsLoadingMore(false);
  })
  .catch((err) => {
    setIsLoadingMore(false);
    console.log("err", err);
  });
};


const getColorByDifficulte = (difficulte) => {
  switch (difficulte) {
    case 1:
      return '#13c2c2';
    case 2:
      return '#722ED1';
    case 3:
      return '#EB2F96';
    case 4:
      return '#ef761d';
    default:
      return '#000';
  }
};
console.log("dataAteliers",dataAteliers);
console.log("isLoading",isLoading);
  return (
    <>
    <Helmet>
    <script type="application/ld+json">
           {JSON.stringify({
             "@context": "https://schema.org",
             "@type": "ItemList",
             itemListElement: dataAteliers && dataAteliers?.map((Ate, i) => ({
               "@type": "ListItem",
               position: Ate?.Meta?.Meta?.Position,
               item: {
                 "@type": "Product",
                 name: Ate?.Meta?.Meta?.Titre,
                 image: Ate?.Meta?.Meta?.URLImage
                   ? "https://www.selftissus.fr" +
                   Ate?.Meta?.Meta?.URLImage
                   : "/logo.svg",
                 description: Ate?.Meta?.Meta?.Description,
                 sku: Ate?.Meta?.Meta?.CodeSKU,
                 brand: {
                   "@type": "Brand",
                   name: Ate?.Meta?.Meta?.Brand,
                 },
                 offers: {
                   "@type": "Offer",
                   url:
                     "https://www.selftissus.fr" + Ate?.Meta?.Meta?.URL,
                   priceCurrency: "EUR",
                   price: Ate?.Meta?.Meta?.Tarif,
                   priceValidUntil: Ate?.Meta?.Meta?.TarifExpiration,
                   itemCondition: "https://schema.org/NewCondition",
                   availability: "https://schema.org/InStock",
                 },
                 aggregateRating: {
                   "@type": "AggregateRating",
                   ratingValue: Ate?.Meta?.Meta?.RatingValue,
                   ratingCount: Ate?.Meta?.Meta?.RatingCountp,
                   bestRating: Ate?.Meta?.Meta?.BestRating,
                 },
               },
             })),
           })}
         </script>
         </Helmet>
         
      <Section>
         {((dataAteliers?.length === 0 || dataAteliers === undefined) && !isLoading) ? <p className="center">Aucun atelier pour le moment</p> :( 
         <WrapperProds>
          <FilterProds>
          <ul className="none">
            <Tri>Trier par</Tri>
            <li><Link onClick={() => handleFilterChange('PrixC')}>Prix croissant</Link></li>
            <li><Link onClick={() => handleFilterChange('PrixD')}>Prix décroissant</Link></li>
            <li><Link onClick={() => handleFilterChange('a')}>De A à Z</Link></li>
            <li><Link onClick={() => handleFilterChange('z')}>Z à A</Link></li>
          </ul>
          </FilterProds>
          <Prods>
          {isLoading && <SpinnerST />} 
          {isLoadingMore && <SpinnerST />} 
            <FilterProds2>
            <FormStyled>
                <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={handleFilterChange}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC">Prix croissant</option>
                  <option value="PrixD">Prix décroissant</option>
                  <option value="a">De A à Z</option>
                  <option value="z">Z à A</option>
                </InputStyled>
              </FormStyled>
              <Result>{dataAteliers?.length} / {dataAteliersNbr}  atelier(s)</Result>
            </FilterProds2>
            <SpaceStyled>
          {
            dataAteliers && getFilteredData()?.map((item, i) => {
              return (
                <Badge.Ribbon text={"Difficulté :"+ " "+item?.Difficulte} color={getColorByDifficulte(item?.Difficulte)}>
                <CardStyled
                  onClick={() => window.location.href = item?.slug}
                  key={i}
                  hoverable
                  // cover={
                  // <Image alt={item?.img?.alt} 
                  // style={{backgroundImage: item?.Photo[0]?.Photo?.url ? 
                  //   `url(${item?.Photo[0]?.Photo?.url})` : 
                  // `url(${imgDefault})`}}>
                  // </Image>}
                   cover={
                    <Img
                      alt="photo de l'atelier"
                      src={item?.image?.url || imgDefault}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = imgDefault;
                      }}
                      loading="lazy"
                    />
                  }
                >
                  <h2 className="grey initial m0 fs17">{item?.lib}</h2>
                  <p className="uppercase m0"><span className="gras">Durée:</span>  <span className="uppercase orange fw600">{formatDuration(item?.Duree)}</span></p>
                  {/* <p className="uppercase"><span className="gras">Prix PUHT :</span>  <span className="uppercase orange fw600">{item?.PUHT} €</span></p> */}
                  <p className="uppercase m0"><span className="uppercase orange fw600">{item?.PUTTC?.toFixed(2)} €</span></p>
                  <ProdsSmall>
                  {item?.Produits && <hr></hr>}
                  {item?.Produits?.map((prod, i) => {
                        return (
                          <Prod href={prod?.slug}><FaRegHandPointRight/>{prod?.lib}</Prod>
                        );
                      }
                    )}
                  </ProdsSmall>
                </CardStyled>
                </Badge.Ribbon>
              );
            })
          }
          </SpaceStyled>
          {dataAteliers?.length < dataAteliersNbr? (
            <div style={{width: "100%"}}>
            <BoutonTransparentBtnST margin={"0 auto"} display={"block"}
            onClick={(event) => handleMoreAteliers(event, dataAteliers?.length, 20)}
            lib="Voir plus d'ateliers"
            />
            </div>
          ) : null}
          </Prods>  
        </WrapperProds>)}
    
      </Section>
      </>
  );
}