import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { FormConnect,InputStyled } from "../DesignSystem/SelfTissus/Forms";
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import PopErrorST from "../PopupsST/PopError";
import { navigate } from "@reach/router";
import { AiOutlineSearch } from "react-icons/ai";

const BoutonSearch = styled.button`
border: none;
background-color: transparent;
position: absolute;
right: -20px;
top: 50%;
transform: translate(-50%,-50%);
&>svg {
  transition:   fill 0.3s ease;
  fill: ${(props) => props.theme.grey05ST};
  height: 30px;
  width: 30px;
}
&:hover {
  cursor: pointer;
  &>svg {
    fill: ${(props) => props.theme.redST};
  }
}
`

export function FormSearchST({className,placeholder,recherche,mode, margin, onClose,rechercheParams}) {
  
    const [dataSearch, setDataSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState('');
    const [inputValue, setInputValue] = useState(recherche ? recherche : rechercheParams);
    useEffect(() => {
  
      setInputValue(recherche ? recherche : "");
    }, [recherche]);


    const fetchData = () => {
        setIsLoading(true);
        axios.post(`/users/recherche`, { Recherche: inputValue })
          .then((response) => {
            setDataSearch(response.data);
            setIsLoading(false);
             navigate(`/recherche/?recherche=${inputValue}`,{ state: { data: response.data,inputValue: inputValue, mode: mode } });
          })
          .catch((error) => {
            setIsLoading(false);
            setDataSearch([]);
            setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur  : "Une erreur s'est produite. Veuillez réessayer ultérieurement.");
            setIsErrorVisible(true);
          });
      };
      

      useEffect(() => {
      
if (rechercheParams){
        setInputValue(rechercheParams? rechercheParams : "");
     
fetchData();
}
    }, []);

    const handleChange = (event) => {
      setInputValue(event.target.value);
    };
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        fetchData();
        if (mode === "nav" && onClose) {
          onClose();
        }
      }
    };
    const handleClick = () => {
      fetchData();
      if (mode === "nav" && onClose) {
        onClose();
      }
    };
        return(
        <>
        {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Recherche"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        <FormConnect className={[className]} margin={margin}>
            <InputStyled
            id="exampleSearch"
            name="search"
            placeholder={placeholder && placeholder !== "" ? placeholder : "Rechercher un produit, un tuto, un conseil..."}
            type="search"
            value={inputValue} 
            onChange={handleChange} 
            onKeyDown={handleKeyDown}
            />
            <BoutonSearch onClick={handleClick}><AiOutlineSearch/></BoutonSearch>
        </FormConnect>
        
        </>
    )
}

export default FormSearchST;
