import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import styled from "styled-components";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import BoutonPayPalST from "../DesignSystem/SelfTissus/BoutonPayPal";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`;

const WrapInfos = styled.div`
position: relative;
width: 100%;
`;

const Buts = styled.div`
margin-top: 20px;
display: flex;
flex-direction: row;
gap: 20px;
width: 100% !important;
justify-content: flex-end;
flex-wrap: wrap;
&>button {
  margin: 0;
}
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
@media only screen and (max-width: 786px) {
    text-align: center;
}
`;



export default function PaiementST({className, data, atelierOnly}) {
console.log("paiement",atelierOnly);
  const [dlPatienter, setDlPatienter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [showPaiement, setShowPaiement] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const dispatch = useDispatch();
 

  const handleDownload = async (event, token, num) => {
    event.preventDefault();
    event.stopPropagation();
    setDlPatienter(true);
    try {
      const response = await axios.post(`/users/download/`,{ "TokenDL":token});
      const base64String = response.data.Data64;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${num}.pdf`); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDlPatienter(false);
    } catch (error) {
      console.error('Failed to download the file:', error);
      setDlPatienter(false);
    }
  };

  const clearAllData = () => {
    dispatch({
      type: "DELETE_ADRESSE",
    });
    dispatch({
      type: "DELETE_BASKET",
    });
    dispatch({
      type: "DELETE_MODELIVR",
    });
    dispatch({
      type: "DELETE_RELAIS",
    });
    dispatch({
      type: "DELETE_DATE",
    });
    dispatch({
      type: "DELETE_HEURE",
    });
    dispatch({
      type: "SET_MAGASIN",
      payload: {
          ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
          Nom: "WEB"
      }
    });
  };

  const payerPanier = (mode) => {
    setIsLoading(true);
    axios.post( `/users/payerpanier`, {Mode : mode})
    .then(response => {
      const slug = response?.data?.slug;
      if (slug) {
        if (slug) {
          window.location.href = slug; 
        }
      }
      setIsLoading(false);
    })
    .catch(error => {
      console.log("error", error?.response);
      setIsLoading(false);
      setIsErrorVisible(true);
      setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur : "Une erreur est survenue lors de la procédure de paiement.");
      // dispatch({type: "DELETE_USER"});
    });
  };


    return (
      <WrapInfos>
      <h2 className="center homemadeTitle initial green">Paiement</h2>
      {dlPatienter && <div className="center"><SpinnerST/></div>}
      {isLoading && <SpinnerST />}
      {isErrorVisible && <PopErrorST message={txt} classNameBut={"white"} titre={"Paiement"} onClick={() => { setIsErrorVisible(false);}} />}
        <Total className={className}>
          <Prix className="fs20">Frais de livraison : <span> {data?.FraisPorts} EUR</span></Prix>
          <Prix className="fs20">Total HT : <span> {data?.MontantHT} EUR</span></Prix>
          <Prix className="fs20">Total TTC : <span> {data?.MontantTTC} EUR</span></Prix>
          <Prix className="fs20">Total TVA : <span> {data?.MontantTVA} EUR</span></Prix>
          <Prix className="fs20 bold">Total : <span className="red bold"> {data?.MontantTTC} EUR</span></Prix>
          <Buts>
            <BoutonNoirBtnST className="mob" lib="Télécharger le devis" onClick={(e) => handleDownload(e, data?.TokenDL, data?.NumDoc)} />
            <BoutonNoirBtnST className="mob" lib="Payer (CB)" onClick={(e) => payerPanier(1)} />
            <BoutonPayPalST className="mob" lib="PayPal" onClick={(e) => payerPanier(2)} />
          </Buts> 
          </Total>
     
      </WrapInfos>
  );
}
