import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { FaPaypal } from "react-icons/fa6";

const Btn = styled.button`
font-family: "Assistant", sans-serif;
border: none;
letter-spacing: 0.06rem;
font-weight: 700;
text-decoration: none;
border: none;
background: #ffd140;
text-align: center;
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "auto"};
padding: ${props => props.padding || "20px"};
min-width: 180px;
position: relative;
cursor: pointer;
color: #003087; 
font-size: 17px;
transition: all .2s ease-out;
&> svg {
    margin-right: 10px;
}
&:hover {
    transform: scale(1.1);
    text-decoration: none;
    color: #fff; 
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonPayPalST(props) {


    return <Btn className={props.className} margin={props.margin}  padding={props.padding} width={props.width} widthMob={props.widthMob} onClick={props.onClick}><FaPaypal/>{props.lib}</Btn>   


}