import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";

const Wrap = styled.div`
position: relative;
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 0;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const Buts = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
`


export default function FormAdresseLivraisonST({onClose,adr,adrIndex}) {
  console.log("adr2222222222222", adr);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user?.Contact);
  const [pays, setPays] = useState([]);
  const [isLoadingPays, setIsLoadingPays] = useState(false);
  
  const [dataForm, setDataForm] = useState(
    {
      Destinataire : adr?.Destinataire,
      Adresse : adr?.Adresse,
      Adresse1 : adr?.Adresse,
      CP : adr?.CP,
      Ville : adr?.Ville,
      ID : adr?.ID,
      IDPays : adr?.IDPays,
    }
  );

  useEffect(() => {
    setIsLoadingPays(true);
    axios.post(`/users/paysliste`)
      .then((response) => {
        console.log("response", response);
        setPays(response.data?.Pays);
        setIsLoadingPays(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingPays(false);
      });
  }, []);

  const sendNewInfos = (type) => {
    console.log("adrIndex", adrIndex);
    console.log("dataForm", dataForm);
    const endpoint = type === "Contact" ? "/users/membresauve" : "/users/membresauveadresselivraison";
    setIsLoading(true);
    axios.post(endpoint, dataForm)
      .then((response) => {
        setIsLoading(false);
        setTxt("Vos informations ont bien été modifiées");
        console.log("response", response);
        if (type === "Contact") {
          // dispatch({
          //   type: "UPDATE_USER",
          //   payload: {
          //     Contact: {
          //       ...user,
          //       TabAdresse: user?.TabAdresse.map((adrRedux, index) =>
          //         adrIndex === index
          //           ? {
          //               ...adrRedux,
          //               ...response.data?.Contact?.TabAdresse[adrIndex],
          //             }
          //           : adrRedux
          //       ),
          //     },
          //   },
          // });
           dispatch({type: "DELETE_USER"})
          dispatch({type: "SET_USER", payload: response.data
          });
        } else if (type === "ContactAdresse") {
          dispatch({
            type: "UPDATE_USER",
            payload: {
              Contact: {
                ...user,
                TabAdresse: user?.TabAdresse.map((adrRedux, index) =>
                  adrRedux?.ID === response.data?.ID
                    ? {
                        ...adrRedux,
                        ...response.data,
                      }
                    : adrRedux
                ),
              },
            },
          });
        }
        console.log("user", user);
        setDataForm({
          Destinataire: "",
          Adresse: "",
          Adresse1: "",
          CP: "",
          Ville: "",
          ID: "",
          IDPays: "",
        });
        setIsErrorVisible(true);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Une erreur s'est produite, veuillez réessayer");
      });
  };


    return (
        <Wrap>
           {isLoading && <SpinnerST />}
           {isErrorVisible && <PopErrorST message={txt} titre={"Informations presonnelles"} onClick={() => { setIsErrorVisible(false);onClose();}} />}
            <Txt> 
              </Txt>
             <FormStyled floating>
                <InputStyled
                    id="nom"
                    name="nom"
                    placeholder="Nom"
                    type="text"
                    value={dataForm?.Destinataire}
                    onChange={(e) => setDataForm({...dataForm, Destinataire: e.target.value})}
                    disabled={adr?.Type === "Contact"}
                />
                  <Label for="email">Destinataire</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="adresse"
                  name="adresse"
                  placeholder="Adresse"
                  type="text"
                  value={dataForm?.Adresse}
                  onChange={(e) => setDataForm({...dataForm, Adresse: e.target.value, Adresse1: e.target.value})}
              />
                <Label for="adresse">Adresse</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="cp"
                  name="cp"
                  placeholder="Code postal"
                  type="text"
                  value={dataForm?.CP}
                  onChange={(e) => setDataForm({...dataForm, CP: e.target.value})}
              />
                <Label for="adresse">Code postal</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="ville"
                  name="ville"
                  placeholder="Ville"
                  type="text"
                  value={dataForm?.Ville}
                  onChange={(e) => setDataForm({...dataForm, Ville: e.target.value})}
              />
                <Label for="ville">Ville</Label>
              </FormStyled>

              <FormStyled floating>
                {isLoadingPays && <SpinnerInlineMiniST/>}
                  <InputStyled
                    id="pays"
                    name="pays"
                    type="select"
                    value={dataForm?.IDPays}
                    onChange={(e) => setDataForm({...dataForm, IDPays: e.target.value})}
                  >
                    <option>Choisir un pays</option>
                    {
                      pays && pays?.sort((a, b) => (a?.Nom > b?.Nom ? 1 : -1)).map((elem, index) => {
                        return (
                          <option key={index} value={elem?.ID}>{elem?.Nom}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="pays">Pays</Label>
                </FormStyled>

              <Buts>
                <BoutonNoirBtnST lib={"Enregistrer"} onClick={() => sendNewInfos(adr?.Type)} />
                <BoutonBlancBtnST lib={"Annuler"} onClick={onClose}/>
              </Buts>
          </Wrap>
  );
}
