import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";

const Wrap = styled.div`
position: relative;
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 0;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const Buts = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
`


export default function FormAdresseST({onClose, user}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();
  const [pays, setPays] = useState([]);
  const [isLoadingPays, setIsLoadingPays] = useState(false);
  // const user = useSelector(state => state.user?.user?.Contact);
  console.log("userFormAdresse", user);
  const [dataForm, setDataForm] = useState(
    {
      Nom : user?.Nom,
      Prenom : user?.Prenom,
      Mail : user?.Mail,
      Tel : user?.Tel,
      Adresse1 : user?.Adresse1,
      Adresse2 : user?.Adresse2,
      CP : user?.CP,
      Ville : user?.Ville,
      DateNaissance : user?.DateNaissance,
      IDPays : user?.IDPays,
    }
  );

useEffect(() => {
  setIsLoadingPays(true);
  axios.post(`/users/paysliste`)
    .then((response) => {
      console.log("response", response);
      setPays(response.data?.Pays);
      setIsLoadingPays(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoadingPays(false);
    });
}, []);


const sendNewInfos = () => {
  console.log("dataForm", dataForm);
setIsLoading(true);
    axios.post(`/users/membresauve`, dataForm)
      .then((response) => {
        console.log("response", response);
        // onClose();
        setIsLoading(false);
        setTxt("Vos informations ont bien été modifiées")
        
        dispatch({
          type: "UPDATE_USER",
          payload: {
            Contact: {
              ...user,
              ...response.data?.Contact,
            },
          },
        });
        console.log("user", user);
        setDataForm({
          Mail: user?.Mail,
          Tel: user?.Tel,
          Adresse1: user?.Adresse1,
          Adresse2: user?.Adresse2,
          CP: user?.CP,
          Ville: user?.Ville,
          DateNaissance: user?.DateNaissance,
          IDPays: user?.IDPays,
        });
        setIsErrorVisible(true);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Une erreur s'est produite, veuillez réessayer")
      });
}


    return (
        <Wrap>
          <h4 className="center homemadeTitle initial green m0">Modifier mes informations</h4>
           {isLoading && <SpinnerST />}
           {isErrorVisible && <PopErrorST message={txt} titre={"Informations presonnelles"} onClick={() => { setIsErrorVisible(false);onClose();}} />}
            <Txt> 
              </Txt>
             <FormStyled floating>
                <InputStyled
                    id="nom"
                    name="nom"
                    placeholder="Nom"
                    type="text"
                    value={dataForm?.Nom}
                    disabled
                    onChange={(e) => setDataForm({...dataForm, Nom: e.target.value})}
                />
                  <Label for="email">Nom</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="prenom"
                  name="prenom"
                  placeholder="Prénom"
                  type="text"
                  disabled
                  value={dataForm?.Prenom}
                  onChange={(e) => setDataForm({...dataForm, Prenom: e.target.value})}
              />
                <Label for="prenom">Prénom</Label>
              </FormStyled>

              <FormStyled floating>
                <InputStyled
                  id="DateNaissance"
                  name="DateNaissance"
                  placeholder="Date de naissance"
                  type="date"
                  value={dataForm?.DateNaissance}
                  onChange={(e) => setDataForm({...dataForm, DateNaissance: e.target.value})}
              />
                <Label for="adresse">Date de Naissance</Label>
              </FormStyled>

             <FormStyled floating>
                <InputStyled
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  value={dataForm?.Mail}
                  onChange={(e) => setDataForm({...dataForm, Mail: e.target.value})}
              />
                <Label for="adresse">E-mail</Label>
              </FormStyled>

              <FormStyled floating>
                <InputStyled
                  id="tel"
                  name="tel"
                  placeholder="Téléphone"
                  type="text"
                  value={dataForm?.Tel}
                  onChange={(e) => setDataForm({...dataForm, Tel: e.target.value})}
              />
                <Label for="tel">Téléphone</Label>
              </FormStyled>

              <FormStyled floating>
                <InputStyled
                  id="adresse"
                  name="adresse"
                  placeholder="Adresse"
                  type="text"
                  value={dataForm?.Adresse1}
                  onChange={(e) => setDataForm({...dataForm, Adresse1: e.target.value})}
              />
                <Label for="adresse">Adresse</Label>
              </FormStyled>

             <FormStyled floating>
                <InputStyled
                  id="adresse"
                  name="adresse"
                  placeholder="Adresse"
                  type="text"
                  value={dataForm?.Adresse2}
                  onChange={(e) => setDataForm({...dataForm, Adresse2: e.target.value})}
              />
                <Label for="adresse">Complément d'adresse</Label>
              </FormStyled>

             
             <FormStyled floating>
                <InputStyled
                  id="cp"
                  name="cp"
                  placeholder="Code postal"
                  type="text"
                  value={dataForm?.CP}
                  onChange={(e) => setDataForm({...dataForm, CP: e.target.value})}
              />
                <Label for="adresse">Code postal</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="ville"
                  name="ville"
                  placeholder="Ville"
                  type="text"
                  value={dataForm?.Ville}
                  onChange={(e) => setDataForm({...dataForm, Ville: e.target.value})}
              />
                <Label for="ville">Ville</Label>
              </FormStyled>
              <FormStyled floating>
                {isLoadingPays && <SpinnerInlineMiniST/>}
                  <InputStyled
                    id="pays"
                    name="pays"
                    type="select"
                    value={dataForm?.IDPays}
                    onChange={(e) => setDataForm({...dataForm, IDPays: e.target.value})}
                  >
                    <option>Choisir un pays</option>
                    {
                      pays && pays?.sort((a, b) => (a?.Nom > b?.Nom ? 1 : -1)).map((elem, index) => {
                        return (
                          <option key={index} value={elem?.ID}>{elem?.Nom}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="pays">Pays</Label>
                </FormStyled>

              <Buts>
                <BoutonNoirBtnST lib={"Enregistrer"} onClick={sendNewInfos} />
                <BoutonBlancBtnST lib={"Annuler"} onClick={onClose}/>
              </Buts>
          </Wrap>
  );
}
