import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled,Legal } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 10px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;

const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
export default function FormInscriptionST({data}) {
  const [magasins, setMagasins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [pays, setPays] = useState([]);
  const [isLoadingPays, setIsLoadingPays] = useState(false);

  const [form, setForm] = useState({
    Civ: 0,
    Nom: "",
    Prenom: "",
    Mail: "",
    Tel: "",
    Adresse: "",
    CP: "",
    Ville: "",
    IDPays: "",
    CGVOk: false
  });

  useEffect(() => {
    setIsLoadingPays(true);
    axios.post(`/users/paysliste`)
      .then((response) => {
        console.log("response", response);
        setPays(response.data?.Pays);
        setIsLoadingPays(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingPays(false);
      });
  }, []);

const inscrire = () => {
  if (!form?.Mail) {
      setIsErrorVisible(true);
      setTxt("Veuillez renseigner votre adresse mail");
      return;
  }
  if (!form?.Tel) {
      setIsErrorVisible(true);
      setTxt("Veuillez renseigner votre numéro de téléphone");
      return;
  }
  if (!form?.CGVOk) {
    setIsErrorVisible(true);
    setTxt("Veuillez acceper les conditions générales");
    return;
  }
  setIsLoading(true);
  axios.post(`/users/membreinscription`, {
    Civ: form.Civ,
    Nom: form.Nom,
    Prenom: form.Prenom,
    Mail: form.Mail,
    Tel: form.Tel,
    Adresse: form.Adresse,
    CP: form.CP,
    Ville: form.Ville,
    IDPays: form.IDPays,
    CGVOk: form.CGVOk,
    DateNaiss: form.DateNaiss,
  }).then(res => {
    console.log(res);
    setForm({
      Civ: "",
      Nom: "",
      Prenom: "",
      Mail: "",
      Tel: "",
      Adresse: "",
      CP: "",
      Ville: "",
      IDPays: "",
      CGVOk: "",
      DateNaiss: "",
    });
    setIsLoading(false);
    setIsErrorVisible(true);
    setTxt("Votre compte a bien été créé.<br>Vous allez recevoir un mail de confirmation avec un mot de passe provisoire.");
  }).catch(err => {
    console.log("err", err);
    setTxt(err?.response?.data?.error ? err.response.data.error : "Une erreur est survenue");
    setIsErrorVisible(true);
    setIsLoading(false);
  });
}
  
    return (
        <Wrap>
           {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Inscription"} onClick={() => setIsErrorVisible(false)}/>}
           {isLoading && <SpinnerST />}
            <Txt> 
              </Txt>
                <Checks>
                  <FormGroup check>
                  <InputStyled
                    name="Civ"
                    type="radio"
                    value={1} 
                    onChange={(e) => setForm({ ...form, Civ: e.target.value })}
                  />
                  <Label check>
                  Monsieur
                  </Label>
                </FormGroup>
                  <FormGroup check>
                  <InputStyled
                    name="Civ"
                    type="radio"
                    value={2}
                    onChange={(e) => setForm({ ...form, Civ: e.target.value })}
                  />
                  <Label check>
                  Madame
                  </Label>
                </FormGroup>
              </Checks>
                <FormStyled floating>
                  <InputStyled
                      id="prenom"
                      name="Prenom"
                      placeholder="Prénom"
                      type="text"
                      value={form?.Prenom}
                      onChange={(e) => setForm({ ...form, Prenom: e.target.value })}
                      autoComplete
                  />
                  <Label for="prenom">Prénom</Label>
                  </FormStyled>

                <FormStyled floating>
                  <InputStyled
                        id="nom"
                        name="Nom"
                        placeholder="Nom"
                        type="text"
                        value={form?.Nom}
                        onChange={(e) => setForm({ ...form, Nom: e.target.value })}
                        autoComplete
                  />
                  <Label for="nom">Nom</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="dateNaiss"
                      name="Date naissance"
                      placeholder="Date de naissance"
                      type="date"
                      value={form?.DateNaiss}
                      onChange={(e) => setForm({ ...form, DateNaiss: e.target.value })}
                      autoComplete
                  />
                  <Label for="dateNaiss">Date de naissance</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="mail"
                      name="Mail"
                      placeholder="E-mail"
                      type="email"
                      value={form?.Mail}
                      onChange={(e) => setForm({ ...form, Mail: e.target.value })}
                      autoComplete
                  />
                  <Label for="mail">E-mail</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="tel"
                      name="Tel"
                      placeholder="Téléphone"
                      type="text"
                      value={form?.Tel}
                      onChange={(e) => setForm({ ...form, Tel: e.target.value })}
                      autoComplete
                  />
                  <Label for="tel">Téléphone</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="Adresse"
                    name="Adresse"
                    placeholder="Adresse"
                    type="textarea"
                    value={form?.Adresse}
                    onChange={(e) => setForm({ ...form, Adresse: e.target.value })}
                />
                  <Label for="Adresse">Adresse</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="cp"
                    name="CP"
                    placeholder="Code postal"
                    type="text"
                    value={form?.CP}
                    onChange={(e) => setForm({ ...form, CP: e.target.value })}
                    autoComplete
                />
                  <Label for="cp">Code postal</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="Ville"
                    name="Ville"
                    placeholder="Ville"
                    type="text"
                    value={form?.Ville}
                    onChange={(e) => setForm({ ...form, Ville: e.target.value })}
                    autoComplete
                />
                  <Label for="Ville">Ville</Label>
                </FormStyled>

                <FormStyled floating>
                {isLoadingPays && <SpinnerInlineMiniST/>}
                  <InputStyled
                    id="pays"
                    name="pays"
                    type="select"
                    value={form?.IDPays}
                    onChange={(e) => setForm({...form, IDPays: e.target.value})}
                  >
                    <option>Choisir un pays</option>
                    {
                      pays && pays?.sort((a, b) => (a?.Nom > b?.Nom ? 1 : -1)).map((elem, index) => {
                        return (
                          <option key={index} value={elem?.ID}>{elem?.Nom}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="pays">Pays</Label>
                </FormStyled>

                <FormGroup check>
                  <InputStyled type="checkbox" 
                  name="CGVOk"
                  checked={form?.CGVOk}
                  onChange={(e) => setForm({ ...form, CGVOk: e.target.checked })}
                  />
                  <Label check>
                  J'accepte les <a href="/informations-legales/cgu" target="_blank">conditions générales</a>
                  </Label>
                </FormGroup>


                <BoutonNoirST lib={"M'inscrire"} onClick={inscrire}/>
                {/* <Legal>
                  {data?.Corps}
                </Legal> */}
               
          </Wrap>
  );
}
